__webpack_public_path__ = "http://dev.local:8080/assets/";

import $ from "jquery";
var imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin( $ );

var sm = ($( window ).width() <= 768);
var md = ($(window).width() > 768 && $(window).width() < 1024);
var lg = ($( window ).width() > 1024);

$( document ).ready(function() {


    var isMobile = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
        }
    };







    $('body').imagesLoaded( function() {
        console.log('image loaded');
    });

    if(!isMobile.any()){
        $('.picture-grid-item').hover(function () {
            $(this).find(".img-overlay").addClass("appear");
        }, function () {
            $(this).find(".img-overlay").removeClass("appear");
        });
    }


    $('#close').click(function () {
        $('.menu-xs').removeClass('appear');
        $('#close').hide();
        $('#open').show();
    });

    $('#open').click(function () {
        $('.menu-xs').addClass('appear');
        $('#close').show();
        $('#open').hide();
    });



    lightbox.option({
        'resizeDuration': 100,
        'wrapAround': true,
        'disableScrolling': true,
        'fadeDuration': 200,
        'imageFadeDuration':200,
        'positionFromTop':0,
        'alwaysShowNavOnTouchDevices':true,
    })


});